<template>
	<div class="company_wrap">
		<NotAct v-if="noAuthencation"></NotAct>
		<FarenHome v-else></FarenHome>
	</div>
</template>
<script>
import {
	certify_latest_info_v2,
	check_certify_status,
} from "@/api/authencation";
import NotAct from "@/views/manage/enterpriseManage/authentication/components/not_ac.vue";
import FarenHome from "@/views/manage/enterpriseManage/authentication/components/faren_home.vue";
import PeoAct from "@/views/manage/enterpriseManage/authentication/components/peo_act.vue";
import FarenAct from "@/views/manage/enterpriseManage/authentication/components/faren_act.vue";
import WaitAct from "@/views/manage/enterpriseManage/authentication/components/wait_act.vue";
export default {
	name: "companydevice",
	components: {
		NotAct,
		FarenHome,
		PeoAct,
		FarenAct,
		WaitAct,
	},
	data() {
		return {
			authStatus: { person: "default", enterprise: "default" },
			noAuthencation: true,
		};
	},

	created() {
		this.auth_info();
	},
	methods: {
		// 获取认证状态，根据此状态改变界面
		async auth_info() {
			//检测个人认证状态
			let { data } = await check_certify_status({
				type: 0,
			});
			if (data.code == 200) {
				this.authStatus.person = data.data;
			}
			//检测企业认证状态
			let res = await certify_latest_info_v2({
				type: 1,
			});
			if (res.data.code == 200) {
				this.authStatus.enterprise = res.data.data.status;
			}
			//如果个人和企业都未认证，返回未认证状态
			this.noAuthencation =
				!this.authStatus.person && !this.authStatus.enterprise;
		},
	},
};
</script>
<style scoped lang="less">
.company_wrap {
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-left: 15px;
	margin-right: 15px;
	height: 100%;
}
</style>
