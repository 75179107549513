import { render, staticRenderFns } from "./faren_home.vue?vue&type=template&id=66b23a0c&scoped=true"
import script from "./faren_home.vue?vue&type=script&lang=js"
export * from "./faren_home.vue?vue&type=script&lang=js"
import style0 from "./faren_home.vue?vue&type=style&index=0&id=66b23a0c&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66b23a0c",
  null
  
)

export default component.exports