import { render, staticRenderFns } from "./add_member.vue?vue&type=template&id=661c79f3&scoped=true"
import script from "./add_member.vue?vue&type=script&lang=js"
export * from "./add_member.vue?vue&type=script&lang=js"
import style0 from "./add_member.vue?vue&type=style&index=0&id=661c79f3&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "661c79f3",
  null
  
)

export default component.exports