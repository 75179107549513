<template>
	<a-modal
		v-model="isshow"
		okText="确定授权"
		:width="1000"
		title="批量授权环境"
		@cancel="handleCancel"
		@ok="ok_handle">
		<div class="top_wrap">
			<p>请选择要授权的环境</p>
			<strong
				>已选择<i>{{ selectedRows.length }}</i
				>个环境</strong
			>
		</div>
		<div class="main">
			<div class="left">
				<div class="item" v-for="item in modaldata" :key="item.id">
					{{ item.username }}
					<a-icon type="close" @click="remove(item)" />
				</div>
			</div>
			<div class="right">
				<div class="search">
					<a-input-search
						placeholder="请输入环境名称"
						style="width: 200px; margin-bottom: 20px"
						@search="go_onSearch" />
					<a-select
						class="select"
						@change="handleChange"
						placeholder="所有标签"
						v-model="check_tagids">
						<a-select-option v-for="item in tagIds" :key="item.id">
							{{ item.tag }}
						</a-select-option>
					</a-select>
					<a-select
						class="select"
						@change="handleChange"
						placeholder="所有平台"
						v-model="platform_id">
						<a-select-option
							v-for="item in platform_options"
							:key="item.id">
							{{ item.site }}
						</a-select-option>
					</a-select>
				</div>

				<a-table
					:row-selection="rowSelection"
					:loading="table_loading"
					:columns="base_columns"
					:data-source="table_data"
					:row-key="(r, i) => r.user_id"
					:scroll="{ y: 300 }"
					:pagination="false"
					@change="handleTableChange">
				</a-table>
				<a-pagination
					style="margin-top: 16px"
					v-model="pagination.pageNum"
					show-size-changer
					:pageSizeOptions="pageSizeOptions"
					:pageSize="pagination.pageSize"
					:total="pagination.total"
					:showTotal="pagination.showTotal"
					@change="handleTableChange"
					@showSizeChange="pageSizeChange" />
				<!-- <a-table :row-selection="rowSelection" :loading="table_loading" :columns="base_columns"
          :data-source="table_data" :row-key="(r, i) => i.toString()" :scroll="{ y: 300 }" :pagination="pagination"
          @change="handleTableChange">
        </a-table> -->
			</div>
		</div>
		<p class="tip">
			已选 <i>{{ modaldata.length }}</i> 名成员，将授权以上<i>{{
				selectedRows.length
			}}</i
			>个环境（不影响原先已授权的环境）
		</p>
	</a-modal>
</template>
<script>
import {
	user_authenvironmentlist,
	user_authenvironmentmore,
	batch_authenvironmentlist,
} from "@/api/member";
import {
	environment_tag_list,
	environment_platform_list,
	choose_environment,
} from "@/api/environment.js";

export default {
	props: {
		modalstatus: Boolean,
		modaldata: Array,
	},
	data() {
		return {
			platform_options: [],
			isshow: false,
			selectedRows: [], //表格选中单元
			tagIds: [],
			check_tagids: "",
			pagination: {
				pageNum: 1, //当前页数
				pageSize: 20, //每页条数
				total: 0,
				showTotal: total => `共 ${total} 条`, // 显示总数
			},
			pageSizeOptions: ["10", "20", "50", "100", "500"],
			table_data: [],
			base_columns: [
				{
					title: "环境名",
					dataIndex: "env_name",
				},
				{
					title: "平台站点",
					dataIndex: "platform",
				},
				{
					title: "标签",
					dataIndex: "tag",
				},
			],
			table_loading: false,
			platform_id: "",
		};
	},
	mounted() {
		this.isshow = this.modalstatus;
		this.get_list();
		this.get_tag_list();

		this.get_platformlist();
	},
	computed: {
		rowSelection() {
			return {
				onChange: (selectedRowKeys, selectedRows) => {
					this.selectedRows = selectedRows;
				},
				getCheckboxProps: record => ({
					// props: {
					//   defaultChecked: this.check_have(record),
					//   name: record.id,
					// },
				}),
			};
		},
	},
	methods: {
		pageSizeChange(current, size) {
			this.pagination.pageSize = size;
			this.handleTableChange(current, size);
		},
		// 分页
		handleTableChange(pagination, pagesize) {
			this.pagination.pageNum = pagination;
			this.get_list();
		},
		// 删除标签
		remove(item) {
			if (this.modaldata.length === 1) {
				this.$message.error("至少选择一个哦~");
				return;
			}
			this.modaldata.forEach((f, idx) => {
				if (f.id === item.id) {
					this.modaldata.splice(idx, 1);
					this.get_list();
				}
			});
		},
		// 平台列表
		async get_platformlist() {
			let { data } = await environment_platform_list({
				type: "all",
				pagesize: 100,
				page: 1,
				status: 0,
			});
			if (data.code == 200) {
				data.data.list.forEach(item => {
					item.country = item.site;
				});
				this.platform_options = data.data.list;
				this.platform_options.unshift({
					id: "999999",
					site: "所有平台",
				});
				this.platform_id = "999999";
			}
		},
		// 标签搜索
		handleChange() {
			this.get_list();
		},
		async get_tag_list() {
			let { data } = await environment_tag_list({
				pagesize: 100,
				page: 1,
			});
			if (data.code == 200) {
				this.tagIds = data.data.list;
				this.tagIds.unshift({
					id: "999999",
					tag: "所有标签",
				});
				this.check_tagids = "999999";
			}
		},
		check_have(record) {
			let c_1 = this.modaldata.auth_environment.findIndex(
				item => item.id == record.id
			);
			if (c_1 > -1) {
				this.selectedRows.push(this.table_data[c_1]);
				return true;
			} else {
				return false;
			}
		},
		handleCancel() {
			this.isshow = false;
			this.$emit("cancel");
		},
		go_onSearch(value) {
			this.get_list(value);
		},
		// 成员授权环境列表
		async get_list(env_name = null) {
			this.table_loading = true;
			let str = {
				pagesize: this.pagination.pageSize,
				page: this.pagination.pageNum,
				env_name: env_name,
			};
			if (this.check_tagids && this.check_tagids !== "999999") {
				str.tag_id = [this.check_tagids.toString()];
			}
			if (this.platform_id && this.platform_id !== "999999") {
				str.platform_id = [this.platform_id.toString()];
			}
			let { data } = await choose_environment(str);
			this.table_loading = false;
			if (data.code == 200) {
				this.table_data = data.data.list;
				this.pagination.total = data.data.total;
			}
		},
		async ok_handle() {
			let c_1 = [];
			this.selectedRows.forEach(item => {
				c_1.push(item.id);
			});
			if (!c_1.toString()) {
				this.$message.error("您至少选择一个环境哦~");
				return;
			}
			let str = {
				env_id: c_1.toString(),
				id: this.modaldata.map(item => item.id).toString(),
			};
			let { data } = await batch_authenvironmentlist(str);
			if (data.code == 200) {
				this.$message.success("授权成功");
				this.isshow = false;
				this.$emit("success");
			}
		},
	},
};
</script>
<style scoped lang="less">
/deep/ .ant-modal-content {
	min-height: 300px;
}

/deep/ .ant-modal-body {
	padding-left: 24px;
	padding-right: 24px;
	padding-top: 10px;
	padding-bottom: 0;
}

/deep/ .ant-modal-footer {
	padding: 10px 24px 20px 24px;
}

.top_wrap {
	display: flex;
	margin-bottom: 16px;
	width: 100%;
	justify-content: space-between;

	p {
		font-size: 14px;
		font-weight: 400;
		color: #374567;
		line-height: 20px;
		margin-bottom: 0;
	}

	strong {
		font-size: 14px;
		font-weight: 400;
		color: #374567;
		line-height: 20px;

		i {
			color: #4c84ff;
			font-style: normal;
		}
	}
}

/deep/ .tab_envment_wrap {
	display: flex;
	height: 58px;
	width: 100%;

	.tab_envment_line {
		padding: 10px;
		width: 100px;
	}
}

.select {
	float: right;
	width: 100px;
	outline: none;

	/deep/ .ant-select-selection {
		border: 0;

		outline: none;

		.ant-select-selection-selected-value {
			outline: none;
		}
	}
}

.tip {
	height: 20px;
	font-size: 14px;
	font-weight: 400;
	color: #374567;
	line-height: 20px;
	margin-top: 12px;

	i {
		color: #4c84ff;
		font-style: normal;
	}
}

.main {
	display: flex;

	.left {
		// display: flex;
		width: 360px;
		border: 1px solid #dedfe2;
		margin-right: 20px;
		padding: 4px 20px 20px 20px;

		.item {
			display: inline-block;
			height: 32px;
			background: #f8f8f8;
			border: 1px solid #dedfe2;
			padding: 0 20px;
			font-size: 14px;
			font-weight: 400;
			color: #374567;
			line-height: 32px;
			margin: 16px 16px 0 0;
			cursor: pointer;
			position: relative;

			.anticon {
				display: none;
				font-size: 12px;
				position: absolute;
				right: 2px;
				top: 2px;
			}

			&:hover {
				.anticon {
					display: flex;
				}
			}
		}
	}

	.right {
		display: flex;
		flex: 1;
		flex-direction: column;

		.ant-table-wrapper {
			width: 100%;
		}
	}
}
</style>
