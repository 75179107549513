<template>
	<div class="member_wrap">
		<div class="content_left">
			<a-menu
				style="width: 210px; height: 100%"
				:default-open-keys="defaultOpenKeys"
				:default-selected-keys="['member']"
				mode="inline"
				:selected-keys="[current]">
				<a-sub-menu key="0" @click="menu_handleClick">
					<div class="menu_one" slot="title">
						<div class="my_equipment menu_txt_right_1"></div>
						<div class="title">成员管理</div>
					</div>
					<a-menu-item key="member"> 所有成员 </a-menu-item>
					<!-- <a-menu-item key="examination"> 申请审批 </a-menu-item> -->
				</a-sub-menu>

				<!-- <a-menu-item key="allUser" class="menu_one">
          <div class="my_equipment menu_txt_right_1"></div>
          <div class="title">成员管理</div>
        </a-menu-item> -->

				<a-menu-item
					key="department"
					@click="menu_handleClick"
					class="menu_one">
					<div class="my_equipment menu_txt_right_2"></div>
					<div class="title">部门管理</div>
				</a-menu-item>

				<a-sub-menu key="1" @click="menu_handleClick">
					<div class="menu_one" slot="title">
						<div class="my_equipment menu_txt_right_1"></div>
						<div class="title">企业管理</div>
					</div>
					<a-menu-item key="authentication" class="menu_one">
						<div class="my_equipment menu_txt_right_2"></div>
						<div class="title">企业认证</div>
					</a-menu-item>

					<!-- <a-menu-item key="examination"> 申请审批 </a-menu-item> -->
				</a-sub-menu>

				<!-- <a-menu-item key="management" @click="menu_handleClick" class="menu_one">
          <div class="my_equipment menu_txt_right_2"></div>
          <div class="title">企业管理</div>
        </a-menu-item> -->

				<!-- this.$router.push({ name: 'company_setting' }) -->

				<!-- <a-sub-menu key="platform">
          <div class="menu_one" slot="title">
            <div class="my_equipment menu_txt_right_1"></div>
            <div class="title">所有角色</div>
          </div>
          <a-menu-item key="boss"> 老板 </a-menu-item>
          <a-menu-item key="jiucai"> 员工 </a-menu-item>
        </a-sub-menu> -->
			</a-menu>
		</div>
		<div class="content_right_test content_right">
			<router-view />
		</div>
	</div>
</template>
<script>
import {
	environment_clear_auth_more,
	choose_environment,
	environment_tag_list,
	environment_platform_list,
} from "@/api/environment.js";
import {
	user_cqqq_list,
	user_rolelist,
	user_deletemembermore,
	user_banmembermore,
	user_enablemembermore,
	user_listdepartment,
	user_adjust_department,
	user_handover_environment,
} from "@/api/member.js";
import { check_certify_status } from "@/api/authencation.js";
import add_member from "./components/add_member.vue";
import edit_department from "./departmentManage/edit_department.vue";
import edit_member from "./components/edit_member.vue";
import auth_environment from "./components/auth_environment.vue";
import auth_environment_batch from "./components/auth_environment_batch.vue";
import view_member from "./components/view_member.vue";
import { TreeSelect } from "ant-design-vue";
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
export default {
	name: "enterprise_manage",
	components: {
		add_member,
		edit_department,
		edit_member,
		auth_environment,
		view_member,
		auth_environment_batch,
	},
	data() {
		return {
			SHOW_PARENT,
			isBumen: false,
			// 部门列表
			listdepartment: [],
			// 批量选择部门
			department_id: [],
			replaceFields: {
				children: "children",
				title: "title",
				value: "id",
				key: "id",
			},
			drawer_visible: false,
			plainOptions: [
				{ label: "老板", value: "1" },
				{ label: "员工", value: "2" },
			],
			plainOptions2: [
				{ label: "启用", value: "0" },
				{ label: "停用", value: "1" },
			],
			labelCol: { span: 4 },
			wrapperCol: { span: 24 },
			wrap_height: null, //wrap高度
			current: "member", //选中的目录
			defaultOpenKeys: ["0"], //默认展开菜单
			batch_visibal: false, //批量气泡状态
			//默认配置
			standard_config: {
				keywords: "",
				role_id: "",
				status: "",
				department_id: "",
				recent: "",
			},

			table_data: [],
			table_loading: false,
			pagination: {
				current: 1, //当前页数
				pageSize: 20, //每页条数
				total: 0,
				showTotal: total => `共 ${total} 条`, // 显示总数
			},

			selectedRowKeys: [], //表格 选中单元序号
			selectedRows: [], //表格 选中单元行数组
			checked_columns: [], //自定义表格头
			columns: [
				{
					title: "用户名",
					dataIndex: "username",
					show: true,
				},
				{
					title: "姓名",
					dataIndex: "real_name",
					show: true,
				},
				{
					title: "所在部门",
					dataIndex: "depart",
					scopedSlots: { customRender: "cell_depart" },
					show: true,
				},
				// {
				//   title: "授权环境",
				//   dataIndex: "auth_method",
				//   scopedSlots: { customRender: "cell_auth" },
				//   show: true,
				// },

				{
					title: "状态",
					dataIndex: "status",
					scopedSlots: { customRender: "cell_status" },
					show: true,
				},

				// {
				//   title: "绑定登录手机号",
				//   dataIndex: "business_phone",
				//   show: true,
				// },
				{
					title: "联系方式",
					dataIndex: "contact",
					show: true,
				},
				{
					title: "创建时间",
					dataIndex: "created_at",
					show: true,
				},

				{
					dataIndex: "operation",
					width: 280,
					fixed: "right",
					slots: { title: "operaTitle" },
					scopedSlots: { customRender: "cell_operate" },
					show: true,
				},
			],

			member_list: [], //成员列表
			role_list: [], //角色列表
			check_data: null, //选中成员

			addmember_modalstatus: false,
			editdepartment_modalstatus: false,
			editmember_modalstatus: false,
			auth_modalstatus: false,
			view_modalstatus: false,
			table_state: true,
			department_state: false,
			// 批量授权
			auth_modalstatus_batch: false,
			check_data_batch: null, //选中成员
			abc: false,
			// 交接
			handover_state: false,
			check_data_handover: {},
			handover_ren_list: [],
			handover_ren: undefined,
			table_loading2: false,
			platform_options: [],
			platform_id: "",
			tagIds: [],
			check_tagids: "",
			pagination2: {
				pageNum: 1, //当前页数
				pageSize: 5, //每页条数
				total: 0,
				showTotal: total => `共 ${total} 条`, // 显示总数
			},
			table_data2: [],
			base_columns: [
				{
					title: "环境名",
					dataIndex: "env_name",
				},
				{
					title: "平台站点",
					dataIndex: "platform",
				},
				{
					title: "标签",
					dataIndex: "tag",
				},
			],
			selectedRowKeys2: [], //表格 选中单元序号
			selectedRows2: [], //表格 选中单元行数组
		};
	},
	created() {
		// this.get_check_certify_status();
		this.changeMenuActive();
	},
	mounted() {
		this.get_rolelist();
		this.set_wrap_height();
		this.get_tabledata();
		// 获取所有部门
		this.get_user_listdepartment();
	},
	computed: {
		rowSelection() {
			return {
				selectedRowKeys: this.selectedRowKeys,
				onChange: (selectedRowKeys, selectedRows) => {
					this.selectedRows = selectedRows;
					this.selectedRowKeys = selectedRowKeys;
				},
				getCheckboxProps: record => ({
					props: {
						disabled: record.role_id === 1,
						name: record.username,
					},
				}),
			};
		},
		rowSelection2() {
			return {
				selectedRowKeys: this.selectedRowKeys2,
				onChange: (selectedRowKeys, selectedRows) => {
					this.selectedRows2 = selectedRows;
					this.selectedRowKeys2 = selectedRowKeys;
				},
				getCheckboxProps: record => ({
					props: {
						disabled: record.role_id === 1,
						name: record.username,
					},
				}),
			};
		},
	},
	methods: {
		// 根据当前路由路径切换左侧菜单激活项
		changeMenuActive() {
			const path = this.$route.path;
			if (path.includes("member")) {
				this.current = "member";
				this.defaultOpenKeys = ["0"];
			} else if (path.includes("department")) {
				this.current = "department";
			} else {
				this.current = "authentication";
				this.defaultOpenKeys = ["1"];
			}
		},
		menu_bumen() {
			// this.$router.push({name:edit_department})
			// this.$router.push('/manage/components/edit_department')
		},
		// 授权环境
		authorization() {
			this.auth_modalstatus_batch = true;
			this.check_data_batch = this.selectedRows;
		},
		// 批量调整部门
		adjustDepartment() {
			this.department_id = [];
			this.department_state = true;
		},
		async editname_handleOk() {
			if (!this.department_id.toString()) {
				this.$message.error("请至少选择一个部门");
				return false;
			}
			let str = {
				department_id: this.department_id.toString(),
				id: this.selectedRowKeys.toString(),
			};
			this.department_state = false;
			let { data } = await user_adjust_department(str);
			if (data.code === 200) {
				this.$message.success("批量修改成功");
			}
		},
		editname_handleCancel() {
			this.department_state = false;
		},
		device_tag_handleChange() {},
		async get_user_listdepartment() {
			let { data } = await user_listdepartment();
			if (data.code === 200) {
				this.listdepartment = data.data.list;
			}
		},
		// 筛选
		open_drawer() {
			this.drawer_visible = true;
		},
		reset_search() {
			this.standard_config.role_id = undefined;
			this.standard_config.status = "all";
			this.standard_config.department_id = undefined;
			this.get_tabledata();
		},
		async onSearch_btn() {
			this.drawer_visible = false;
			this.get_tabledata();
		},
		// 获取是否认证过设备
		async get_check_certify_status() {
			let { data } = await check_certify_status();
			if (!data.data) {
				this.$router.push({
					name: "company_notac",
				});
			}
		},
		//高度绑定为页面高度
		set_wrap_height() {
			this.wrap_height = document.body.clientHeight - 82;
		},
		open_delet(record) {
			let that = this;
			this.$confirm({
				title: "删除成员",
				content: "成员:" + record.username + " ,确定删除吗",
				onOk() {
					that.go_delet(record.id);
					return false;
				},
				onCancel() {},
			});
		},
		batch_opendelet() {
			let c_1 = [];
			this.selectedRows.forEach(item => {
				c_1.push(item.id);
			});
			let that = this;
			this.$confirm({
				title: "批量删除成员",
				content: "确定批量删除所选成员吗",
				onOk() {
					that.go_delet(c_1.toString());
					return false;
				},
				onCancel() {},
			});
		},
		async go_delet(id) {
			let { data } = await user_deletemembermore({
				id: id,
			});
			if (data.code == 200) {
				this.$message.success("删除成功");
				this.get_tabledata();
			}
		},

		open_ban(record) {
			let that = this;
			this.$confirm({
				title: "禁用成员",
				content: "成员:" + record.username + " ,确定禁用吗",
				onOk() {
					that.go_ban(record.id);
					return false;
				},
				onCancel() {},
			});
		},
		batch_ban() {
			let c_1 = [];
			this.selectedRows.forEach(item => {
				c_1.push(item.id);
			});

			let that = this;
			this.$confirm({
				title: "批量禁用成员",
				content: "确定批量禁用所选成员吗",
				onOk() {
					that.go_ban(c_1.toString());
					return false;
				},
				onCancel() {},
			});
		},
		async go_ban(id) {
			let { data } = await user_banmembermore({
				id: id,
			});
			if (data.code == 200) {
				this.$message.success("禁用成功");
				this.get_tabledata();
			}
		},

		//启用
		open_enable(record) {
			let that = this;
			this.$confirm({
				title: "启用成员",
				content: "成员:" + record.username + " ,确定启用吗",
				onOk() {
					that.go_enable(record.id);
					return false;
				},
				onCancel() {},
			});
		},
		batch_enable() {
			let c_1 = [];
			this.selectedRows.forEach(item => {
				c_1.push(item.id);
			});

			let that = this;
			this.$confirm({
				title: "批量启用成员",
				content: "确定批量启用所选成员吗",
				onOk() {
					that.go_enable(c_1.toString());
					return false;
				},
				onCancel() {},
			});
		},
		async go_enable(id) {
			let { data } = await user_enablemembermore({
				id: id,
			});
			if (data.code == 200) {
				this.$message.success("启用成功");
				this.get_tabledata();
			}
		},
		//获得角色列表
		async get_rolelist() {
			let { data } = await user_rolelist({});
			if (data.code == 200) {
				this.role_list = data.data.list;
			}
		},
		//获得表格数据
		async get_tabledata() {
			this.table_loading = true;
			this.selectedRowKeys = [];
			let { data } = await user_cqqq_list({
				...this.standard_config,
				pagesize: 20,
				page: this.pagination.current,
			});
			this.table_loading = false;
			if (data.code == 200) {
				this.pagination.total = data.data.total;
				this.table_data = data.data.list;
			}
		},
		//菜单点击
		menu_handleClick(e) {
			if (e.key == "member") {
				this.isBumen = true;
				this.$router.push({ name: "manage_enterprise_member" });
				this.current = e.key;
			}
			if (e.key == "department") {
				this.$router.push({ name: "manage_enterprise_department" });
				this.current = e.key;
			}
			if (e.key == "authentication") {
				this.isBumen = false;
				this.current = e.key;
				this.$router.push({ name: "manage_enterprise_authentication" });
			}

			// else {
			//   return;
			//   this.isBumen = false;
			//   this.table_state = false;

			//   this.current = e.key;

			//   Object.assign(this.$data, this.$options.data());
			//   this.current = e.key;

			//   if (e.key == "status") {
			//     this.standard_config.status = 1;
			//   }
			//   if (e.key == "recent") {
			//     this.standard_config.recent = 1;
			//   }
			//   if (e.key == "boss") {
			//     this.standard_config.role_id = 1;
			//   }
			//   if (e.key == "jiucai") {
			//     this.standard_config.role_id = 2;
			//   }

			//   this.pagination.current = 1;
			//   this.pagination.total = 1;

			//   this.table_state = true;

			//   this.get_tabledata();
			// }
		},
		//格式化授权
		table_formate_auth(data) {
			if (data == 0) {
				return "全部终端";
			}
			if (data == 1) {
				return "后端";
			}
			if (data == 2) {
				return "新终端";
			}
		},
		//格式化部门
		table_formate_depart(data) {
			let c_1 = [];
			if (data.length == 0) {
				return;
			}
			data.forEach(item => {
				c_1.push(item.title);
			});
			return c_1.toString();
		},
		//格式化状态
		table_formate_status(data) {
			if (data == 0) {
				return "启用";
			} else if (data == 1) {
				return "禁用";
			} else if (data == 2) {
				return "删除";
			} else {
				return "";
			}
		},

		//selectedRowKeys
		//批量操作确认弹窗
		batch_open(title) {
			this.batch_visibal = false;
			let c_1 = [];
			this.selectedRowKeys.forEach(item => {
				c_1.push(this.table_data[item].id);
			});

			let that = this;
			this.$confirm({
				title: title,
				content: "环境:" + c_1.length + "个,确定进行该批量操作吗",
				onOk() {
					that.go_clear_auth(c_1, title);
					return false;
				},
				onCancel() {},
			});
		},
		//批量操作ajax
		async go_clear_auth(idarr, type) {
			if (type == "清空授权") {
				let { data } = await environment_clear_auth_more({
					id: idarr.toString(),
				});
				if (data.code == 200) {
					this.get_tabledata();
					this.$message.success("清空授权 操作成功");
				}
			}
		},

		//获得成员列表
		async get_member_data(keywords) {
			let { data } = await user_cqqq_list({
				keywords: keywords,
			});
			if (data.code == 200) {
				this.member_list = data.data.list;
				this.drawer_memberlist = this.member_list;
			}
		},
		//搜索回调
		handle_search(keywords) {
			this.standard_config.keywords = keywords;
			this.pagination.current = 1;
			this.get_tabledata();
		},

		//表格 切换页码
		handleTableChange(pagination) {
			this.pagination.current = pagination.current;
			this.get_tabledata();
		},

		//添加成员弹窗
		open_addmember_pop() {
			this.addmember_modalstatus = true;
		},
		cancel_addmember() {
			this.addmember_modalstatus = false;
		},
		success_addmember() {
			this.addmember_modalstatus = false;
			this.get_tabledata();
		},

		//编辑成员弹窗
		open_editmember_pop(record) {
			this.editmember_modalstatus = true;
			this.check_data = record;
		},
		cancel_editmember() {
			this.editmember_modalstatus = false;
		},
		success_editmember() {
			this.editmember_modalstatus = false;
			this.get_tabledata();
		},

		//授权弹窗
		open_auth_pop(record) {
			this.auth_modalstatus = true;
			this.check_data = record;
		},
		cancel_auth() {
			this.auth_modalstatus = false;
			this.auth_modalstatus_batch = false;
		},
		success_auth() {
			this.auth_modalstatus = false;
			this.auth_modalstatus_batch = false;
			this.get_tabledata();
		},
		//查看用户弹窗
		open_view_pop(record) {
			this.view_modalstatus = true;
			this.check_data = record;
		},
		cancel_view() {
			this.view_modalstatus = false;
		},
		success_view() {
			this.view_modalstatus = false;
			this.get_tabledata();
		},

		//部门管理弹窗
		open_editdepartment_pop() {
			this.editdepartment_modalstatus = true;
		},
		cancel_editdepartment() {
			this.editdepartment_modalstatus = false;
		},
		success_editdepartment() {
			this.editdepartment_modalstatus = false;
			this.get_tabledata();
		},
	},
};
</script>

<style scoped lang="less">
.ant-table-wrapper {
	padding: 0 15px;
}

.table-popover {
	.popover-content {
		width: 90px;
		// padding-top: 8px;
		height: 40px;
		line-height: 40px;
		cursor: pointer;
		text-align: center;
	}

	.popover-content:hover {
		color: #4c84ff;
	}

	.popover_edit-content {
		width: 110px;
		padding-top: 5px;
		//text-align: center;
		height: 40px;
		line-height: 40px;
		cursor: pointer;
		padding: 0 10px;
	}

	.popover_edit-content:hover {
		color: #4c84ff;
	}
}

.member_wrap {
	min-width: 1845px;
	padding-left: 15px;
	margin: 0 auto;
	min-height: calc(100vh - 126px);
	margin-top: 20px;

	//border: 1px solid green;
	display: flex;

	.content_left {
		padding-top: 38px;
		min-width: 210px;
		background-color: white;

		.menu_one {
			display: flex;
			flex-direction: row;
			height: 46px;

			.ant-menu-item-selected {
				background: linear-gradient(90deg, #f7f9ff 0%, #ebf0fc 100%);
			}

			.my_equipment {
				margin-top: 14px;
				margin-right: 7px;
				width: 18px;
				height: 16px;
				background-position: center;
				background-size: 100%;
				background-repeat: no-repeat;
			}

			.menu_txt_right_1 {
				background-image: url("../../../assets/img/member/成员@2x.png");
			}

			.menu_txt_right_2 {
				background-image: url("../../../assets/img/member/已禁用@2x.png");
			}

			.menu_txt_right_3 {
				background-image: url("../../../assets/img/member/成员审核@2x.png");
			}

			.title {
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #374567;
				line-height: 46px;
			}

			.count {
				border: 1px solid #374567;
			}
		}
	}

	.content_right {
		background-color: white;
		flex: 1;
		margin-right: 20px;
		margin-left: 10px;

		.top_line {
			display: flex;
			justify-content: space-between;
			padding-top: 25px;

			.top_l_left {
				display: flex;

				.top_btn {
					margin-left: 15px;
					border-radius: 0;
				}

				.btn_search {
					margin-left: 15px;
					width: 450px;
					height: 30px;
				}
			}

			.top_l_right {
				display: flex;

				.top_btn {
					margin-left: 15px;
					margin-right: 20px;
					border-radius: 0;
					float: left;
					display: flex;
					align-items: center;
					justify-content: center;

					i {
						width: 16px;
						height: 16px;
						background: url(../../../assets/img/filter-icon.png)
							no-repeat center center;
						background-size: 100% 100%;
						display: inline-block;
						margin-right: 4px;
					}
				}

				.btn_search {
					width: 300px;
					height: 30px;
					margin-right: 15px;
					border-radius: 0;

					/deep/ input {
						border-radius: 0;
					}
				}
			}
		}

		.down_table {
			margin-top: 10px;

			.title_operate {
				display: flex;
				width: 200px;
				justify-content: space-between;
			}

			.content_operate {
				display: flex;

				.cell_blue {
					padding-left: 20px;
					line-height: 32px;
					color: #4c84ff;
					cursor: pointer;
				}

				.cell_leftblue {
					color: #4c84ff;
					line-height: 32px;
					cursor: pointer;
				}
			}
		}
	}
}

.cell_status {
	font-size: 14px;
	font-weight: 500;
	color: #00a870;
	line-height: 20px;
	display: flex;
	align-items: center;
	margin-bottom: 0;

	&::before {
		content: "";
		display: inline-block;
		width: 3px;
		height: 3px;
		background: #00a870;
		margin-right: 2px;
	}

	&.disabled {
		color: #e34d59;

		&::before {
			background: #e34d59;
		}
	}
}

.mora {
	font-size: 0;
	width: 32px;
	height: 32px;
	background: url(../../../assets/img/mora-icon.png) no-repeat center center;
	margin-left: 20px;
	background-size: 100% 100%;
}

::v-deep {
	.ant-modal {
		.ant-modal-body {
			padding-top: 0 !important;
		}
	}
}

/deep/ .ant-modal-header {
	border: 0;
}

/deep/ .ant-modal-footer {
	border: 0;
}

.btnNew {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 100%;
	border-top: 1px solid #e9e9e9;
	padding: 12px 16px;
	background: #fff;
	text-align: right;
	z-index: 1;
	display: flex;
	justify-content: space-between;
}

.drawer_btn {
	margin-right: 10px !important;
	background: #f5f5f5;
	border: none;
	font-size: 14px;
	font-weight: 400;
	color: #374567;
	line-height: 22px;
}

/deep/ .ant-form-item-label {
	label {
		float: left;
	}
}

.line_right_top {
	margin-top: 10px;
}

.line_left {
	font-size: 14px;
	font-weight: 400;
	color: #374567;
	line-height: 20px;

	i {
		float: right;
		font-size: 14px;
		font-weight: 400;
		color: #374567;
		line-height: 20px;
		font-style: normal;
	}
}

.r15 {
	margin-left: 20px;
}

.handoverMain {
	display: flex;
	flex-direction: column;

	/deep/ .ant-table-wrapper {
		padding: 0;
		margin-top: 20px;
	}

	.top_wrap {
		display: flex;
		margin-bottom: 14px;
		font-size: 14px;
		font-weight: 400;
		color: #374567;
		line-height: 20px;

		div {
			display: flex;
			align-items: center;

			&::after {
				content: "";
				margin: 0 10px;
				width: 1px;
				height: 15px;
				background: #999999;
			}

			i {
				color: #4c84ff;
				font-style: normal;
			}

			&:last-child {
				&::after {
					display: none;
				}
			}
		}
	}

	.chi {
		margin-bottom: 16px;
		font-size: 14px;
		font-weight: 400;
		color: #374567;
		line-height: 20px;

		p {
			float: left;
			margin: 0 !important;
			height: 32px;
			line-height: 32px;
		}

		i {
			display: inline-flex;
			background: url(../../../assets/img/mora-icon2.png) no-repeat center
				center;
			width: 16px;
			height: 32px;
			background-size: 16px 16px;
			float: left;
		}
	}

	.tip {
		font-size: 14px;
		font-weight: 400;
		color: #374567;
		line-height: 20px;
	}

	/deep/ .jren {
		float: left;
		width: 600px;

		.ant-select-selection__rendered {
			margin: 0 0 0 5px;
		}

		.ant-select-selection__choice {
			float: left;
			background: #f0f4ff;
			border-radius: 2px;
			border: 1px solid #4c84ff;

			.ant-select-selection__choice__content {
				color: #4c84ff;
			}
		}

		.ant-select-selection__rendered {
			width: 100%;
		}

		.ant-select-selection-selected-value {
			font-size: 14px;
			font-weight: 400;
			color: #4c84ff;
		}

		.ant-select-selection {
			border: 0;

			.ant-select-selection__placeholder {
				font-size: 14px;
				font-weight: 400;
				color: #4c84ff;
			}

			.ant-select-arrow {
				display: none;
			}
		}
	}
}

.right {
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-top: 12px;

	.search {
		display: flex;
		width: 100%;
		justify-content: space-between;

		/deep/ .ant-select-selection {
			border: 0;
		}
	}

	.ant-table-wrapper {
		width: 100%;
	}
}

.handoverBox {
	position: relative;

	.tipBottom {
		position: absolute;
		bottom: 50px;
		left: 30px;

		height: 20px;
		font-size: 14px;
		font-weight: 400;
		color: #374567;
		line-height: 20px;
		margin-top: 12px;

		i {
			color: #4c84ff;
			font-style: normal;
		}
	}

	/deep/ .ant-modal-body {
		padding-bottom: 0;
	}

	/deep/ .ant-modal-footer {
		padding-bottom: 40px;
	}
}
</style>
