var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"faren_wrap"},[(!_vm.iskoa && !_vm.iskob)?_c('div',{staticClass:"top_line"},[_c('div',{staticClass:"top_line_1"},[_vm._v("温馨提示")]),_c('div',{staticClass:"top_line_2"},[_vm._v(" 目前仅支持中国工商局注册的企业提交企业认证。 ")]),_c('div',{staticClass:"top_line_2"},[_vm._v(" 请根据资料提示准备好材料，选择任一认证方式开始认证，请勿中图退出，否则将重新开始认证流程。 ")])]):_vm._e(),(!_vm.iskoa && !_vm.iskob)?_c('div',{staticClass:"content"},[_vm._m(0),_c('div',{staticClass:"content_top"},[_c('div',{staticClass:"con_t_left"},[_c('div',{staticClass:"line_1"},[_c('div',{staticClass:"left_num"},[_vm._v("1")]),_c('div',{staticClass:"left_txt"},[_vm._v("个人认证")]),_c('div',{class:[
								_vm.peo_status == 0
									? 'left_no_state'
									: 'left_active_state',
							]},[_vm._v(" "+_vm._s(_vm.pep_status)+" ")])]),_vm._m(1)]),_c('div',{staticClass:"con_t_right"},[(!_vm.peo_status)?_c('a-button',{staticClass:"right_btn",attrs:{"type":"primary"},on:{"click":_vm.go_pop}},[_vm._v(" "+_vm._s(_vm.pep_btn)+" ")]):_vm._e()],1)]),_c('div',{staticClass:"content_down"},[_c('div',{staticClass:"con_t_left"},[_c('div',{staticClass:"line_1"},[_c('div',{staticClass:"left_num"},[_vm._v("2")]),_c('div',{staticClass:"left_txt"},[_vm._v("组织认证")]),_c('div',{class:[
								_vm.company_status == 0
									? 'left_no_state'
									: 'left_active_state',
							]},[_vm._v(" "+_vm._s(_vm.com_status)+" ")])]),_c('div',{staticClass:"com_line_yellow"},[_vm._v(" 所需资料：营业执照照片或扫描件 ")]),_c('div',{staticClass:"com_line_yellow"},[_vm._v(" 注：必须先进行个人认证才能进行组织认证 ")])]),_c('div',{staticClass:"con_t_right"},[(_vm.company_status != 1)?_c('a-button',{staticClass:"right_btn",attrs:{"type":"primary","disabled":!_vm.peo_status},on:{"click":_vm.go_com}},[_vm._v(" "+_vm._s(_vm.com_btn)+" ")]):_vm._e()],1)])]):_vm._e(),(_vm.iskoa)?_c('peoact',{on:{"complete":_vm.cancelPerson}}):_vm._e(),(_vm.iskob)?_c('farenac',{on:{"complete":_vm.cancelEnterprise}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_title"},[_vm._v(" 企业认证 / "),_c('span',{staticStyle:{"color":"#3b77fa"}},[_vm._v("法人认证（人脸识别）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left_middle"},[_c('div',{staticClass:"left_m_line"}),_c('div',{staticClass:"left_m_right"},[_c('div',{staticClass:"top"},[_vm._v(" 请提交法人中国大陆身份证正反面照片 ")]),_c('div',{staticClass:"down"},[_vm._v(" 非中国大陆居民的法人无法进行企业认证 ")])])])
}]

export { render, staticRenderFns }